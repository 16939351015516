import FullscreenIcon from 'mdi-preact/FullscreenIcon';
import FullscreenExitIcon from 'mdi-preact/FullscreenExitIcon';
import { useCallback, useState } from 'preact/hooks';
import { Fragment } from 'preact';
import ToolbarButton from './ToolbarButton';
import TRANSLATIONS from '../../../translations';
import {
  sendExitFullScreenMessage,
  sendFullScreenMessage,
} from '../../../util/parent-system-message-api';

function FullScreen({ language }) {
  const [fullScreen, setFullScreen] = useState(false);
  const enterFullScreen = useCallback(() => {
    sendFullScreenMessage();
    setFullScreen(true);
  }, []);
  const exitFullScreen = useCallback(() => {
    sendExitFullScreenMessage();
    setFullScreen(false);
  }, []);

  return (
    <Fragment>
      {fullScreen ? (
        <ToolbarButton
          onClick={exitFullScreen}
          tooltipText={TRANSLATIONS.EXIT_FULL_SCREEN[language]}
          testid="exit-full-screen-btn"
        >
          <FullscreenExitIcon />
        </ToolbarButton>
      ) : (
        <ToolbarButton
          onClick={enterFullScreen}
          tooltipText={TRANSLATIONS.FULL_SCREEN[language]}
          testid="full-screen-btn"
        >
          <FullscreenIcon />
        </ToolbarButton>
      )}
    </Fragment>
  );
}

export default FullScreen;
