import LoadingIcon from 'mdi-preact/LoadingIcon';
import translations, { DEFAULT_LANGUAGE } from '../../translations';

function LoadingScreen({ spinnerColor = '#4d4d4d', language }) {
  return (
    <div className="loading-screen" data-testid="loading-screen">
      <LoadingIcon data-testid="spinner" style={{ color: spinnerColor }} />
      <span className="loading-text" data-testid="loading-text">
        {`${
          translations.LOADING[language] ||
          translations.LOADING[DEFAULT_LANGUAGE]
        }...`}
      </span>
    </div>
  );
}

export default LoadingScreen;
