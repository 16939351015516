export default function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const dataView = new DataView(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    dataView.setUint8(i, byteString.charCodeAt(i));
  }
  return new Blob([arrayBuffer], { type: mimeString });
}
