import { useCallback, useEffect, useState } from 'preact/hooks';

function useDrawIoPluginEvents({ onViewerEvent, onExportEvent }) {
  const [readyToListen, setReadyToListen] = useState(false);

  const receiveDrawIoPluginMessages = useCallback(
    (evt) => {
      if (evt.data.viewerEvent) {
        onViewerEvent?.(evt);
      }
      if (evt.data.exportEvent) {
        onExportEvent?.(evt);
      }
    },
    [onViewerEvent, onExportEvent],
  );

  useEffect(() => {
    window.addEventListener('message', receiveDrawIoPluginMessages);
    setReadyToListen(true);
    return () => {
      window.removeEventListener('message', receiveDrawIoPluginMessages);
    };
  }, [receiveDrawIoPluginMessages]);

  return readyToListen;
}

export default useDrawIoPluginEvents;
