import axios from 'axios';

export function postDrawing(data, { jwt }) {
  return axios.post('/api/v1/drawings', data.xml, {
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/xml',
    },
  });
}

export function putDrawing(id, data, { jwt }) {
  return axios.put(`/api/v1/drawings/${id}`, data.xml, {
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/xml',
    },
  });
}
