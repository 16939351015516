export const CONFIGURE = 'configure';
export const SAVE = 'save';
export const UPDATE = 'update';
export const EXIT = 'exit';
export const LOAD = 'load';
export const IMAGE = 'image';
export const HOVER = 'hover';
export const HOVER_OUT = 'hoverOut';
export const CLICK = 'click';
export const CLICK_LINK_ICON = 'clickLinkIcon';
export const SAVING_SUCCESS = 'savingSuccess';
export const SAVING_FAILED = 'savingFailed';
export const REFRESH_JWT = 'refreshJwt';
export const INVALID_JWT = 'invalidJwt';
export const HIGHLIGHT = 'highlight';
export const GET_IMAGE = 'getImage';
export const RENDER_LINK_ICONS = 'renderLinkIcons';
export const REMOVE_LINK_ICONS = 'removeLinkIcons';
export const ACTIVATE_LINK_ICONS = 'activateLinkIcons';
export const DEACTIVATE_LINK_ICONS = 'deactivateLinkIcons';
export const FULL_SCREEN = 'fullScreen';
export const EXIT_FULL_SCREEN = 'exitFullScreen';

function sendMessageToParentSystem(message, data) {
  window.parent.postMessage(
    {
      message,
      iframeName: window.name,
      ...data,
    },
    '*',
  );
}

export function sendSaveMessage(id, image, objectData) {
  sendMessageToParentSystem(SAVE, {
    drawId: id,
    image,
    objectData,
  });
}

export function sendUpdateMessage(id, image, objectData) {
  sendMessageToParentSystem(UPDATE, {
    drawId: id,
    image,
    objectData,
  });
}

export function sendExitMessage() {
  sendMessageToParentSystem(EXIT);
}

export function sendLoadMessage(height) {
  sendMessageToParentSystem(LOAD, {
    height,
  });
}

export function sendViewerEventMessage(event) {
  sendMessageToParentSystem(event.data.viewerEvent, {
    target: event.data.target,
    ...(event.data.mousePosition && {
      mousePosition: event.data.mousePosition,
    }),
  });
}

export function sendImageMessage(dataURI) {
  sendMessageToParentSystem(IMAGE, {
    image: dataURI,
  });
}

export function sendConfigureMessage() {
  sendMessageToParentSystem(CONFIGURE);
}

export function sendInvalidJwtMessage() {
  sendMessageToParentSystem(INVALID_JWT);
}

export function sendFullScreenMessage() {
  sendMessageToParentSystem(FULL_SCREEN);
}

export function sendExitFullScreenMessage() {
  sendMessageToParentSystem(EXIT_FULL_SCREEN);
}
