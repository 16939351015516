import { useEffect, useState, useCallback } from 'preact/hooks';
import LayersIcon from 'mdi-preact/LayersOutlineIcon';
import ToolbarButtonWithDropdown from './ToolbarButtonWithDropdown';
import ToolbarButton from './ToolbarButton';
import LayerDropdown from './LayerDropdown';
import TRANSLATIONS from '../../../translations';
import {
  sendGetLayersMessage,
  sendToggleLayerMessage,
  sendAddLinkIconInteractionToLayerMessage,
} from '../../../util/drawio-plugin-message-api';

function Layers({ toggleDropdown, showDropdown, drawIoRef, language }) {
  const [layers, setLayers] = useState([]);

  const toggleLayer = useCallback(
    ({ id, visible }) => {
      sendToggleLayerMessage(drawIoRef.current, id);
      if (!visible) {
        sendAddLinkIconInteractionToLayerMessage(drawIoRef.current, id);
      }
    },
    [drawIoRef],
  );

  const receive = useCallback((evt) => {
    if (evt.data.layers) {
      setLayers(evt.data.layers);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', receive);
    sendGetLayersMessage(drawIoRef.current);
    return () => {
      window.removeEventListener('message', receive);
    };
  }, [receive, drawIoRef]);

  return (
    <ToolbarButtonWithDropdown
      button={
        <ToolbarButton
          onClick={toggleDropdown}
          tooltipText={TRANSLATIONS.LAYERS[language]}
          disabled={layers.length < 2}
          selected={showDropdown}
          testid="layers-btn"
        >
          <LayersIcon />
        </ToolbarButton>
      }
      dropdown={<LayerDropdown layers={layers} onItemChange={toggleLayer} />}
      showDropdown={showDropdown}
    />
  );
}

export default Layers;
