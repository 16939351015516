import { useCallback } from 'preact/hooks';
import DownloadIcon from 'mdi-preact/DownloadOutlineIcon';
import ToolbarButton from './ToolbarButton';
import Dropdown from './Dropdown';
import DropdownListItem from './DropdownListItem';
import ToolbarButtonWithDropdown from './ToolbarButtonWithDropdown';
import TRANSLATIONS from '../../../translations';
import downloadDataURI from '../../../util/download-data-uri';

function Exports({
  toggleDropdown,
  showDropdown,
  uuid,
  xml,
  language,
  onExportPng,
}) {
  const exportXml = useCallback(() => {
    const file = new Blob([xml], { type: 'text/xml' });
    const filename = `${uuid}.xml`;
    if (window.navigator && window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(file, filename);
    } else {
      downloadDataURI(URL.createObjectURL(file), filename);
    }
  }, [uuid, xml]);

  return (
    <ToolbarButtonWithDropdown
      button={
        <ToolbarButton
          onClick={toggleDropdown}
          tooltipText={TRANSLATIONS.EXPORT[language]}
          selected={showDropdown}
          testid="export-btn"
        >
          <DownloadIcon />
        </ToolbarButton>
      }
      dropdown={
        <Dropdown testid="export-dropdown-container">
          <DropdownListItem onClick={onExportPng} text="PNG" />
          <DropdownListItem onClick={exportXml} text="XML" />
        </Dropdown>
      }
      showDropdown={showDropdown}
    />
  );
}

export default Exports;
