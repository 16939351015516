import { useState, useEffect, useCallback } from 'preact/hooks';
import ToolbarButton from './ToolbarButton';
import Dropdown from './Dropdown';
import DropdownValueItem from './DropdownValueItem';
import ToolbarButtonWithDropdown from './ToolbarButtonWithDropdown';
import TRANSLATIONS from '../../../translations';
import {
  sendGetScaleMessage,
  sendScaleMessage,
} from '../../../util/drawio-plugin-message-api';

function Zoom({ toggleDropdown, showDropdown, drawIoRef, language }) {
  const [zoom, setZoom] = useState(100);

  const onZoomClick = useCallback(
    (value) => {
      sendScaleMessage(drawIoRef.current, value);
      toggleDropdown();
    },
    [drawIoRef, toggleDropdown],
  );

  const receive = useCallback((evt) => {
    if (evt.data.scale) {
      setZoom(Math.trunc(evt.data.scale * 100));
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', receive);
    sendGetScaleMessage(drawIoRef.current);
    return () => {
      window.removeEventListener('message', receive);
    };
  }, [receive, drawIoRef]);

  return (
    <ToolbarButtonWithDropdown
      button={
        <ToolbarButton
          onClick={toggleDropdown}
          tooltipText={TRANSLATIONS.ZOOM[language]}
          selected={showDropdown}
          testid="zoom-btn"
        >
          {`${zoom}%`}
        </ToolbarButton>
      }
      dropdown={
        <Dropdown testid="zoom-dropdown-container">
          <DropdownValueItem
            onValueClick={onZoomClick}
            text="50%"
            value={0.5}
          />
          <DropdownValueItem onValueClick={onZoomClick} text="100%" value={1} />
          <DropdownValueItem onValueClick={onZoomClick} text="200%" value={2} />
        </Dropdown>
      }
      showDropdown={showDropdown}
    />
  );
}

export default Zoom;
