import { useState, useEffect, useRef, useCallback } from 'preact/hooks';

function useComponentVisible(current) {
  const [visible, setVisible] = useState(current);
  const ref = useRef(null);

  const handleClickOutside = useCallback(
    (event) => {
      if (ref.current.base && !ref.current.base.contains(event.target)) {
        setVisible(false);
      }
    },
    [ref],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  return { ref, visible, setVisible };
}

export default useComponentVisible;
