'use strict';

var preact = require('preact');

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var objectWithoutProperties = function (obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
};

var LoadingIcon = function LoadingIcon(_ref) {
  var _ref$color = _ref.color,
      color = _ref$color === undefined ? 'currentColor' : _ref$color,
      _ref$size = _ref.size,
      size = _ref$size === undefined ? 24 : _ref$size,
      children = _ref.children,
      props = objectWithoutProperties(_ref, ['color', 'size', 'children']);

  var className = 'mdi-icon ' + (props.class || props.className || '');

  return preact.h(
    'svg',
    _extends({}, props, { 'class': className, width: size, height: size, fill: color, viewBox: '0 0 24 24' }),
    preact.h('path', { d: 'M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z' })
  );
};

module.exports = LoadingIcon;
