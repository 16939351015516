/* eslint-disable no-param-reassign */
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { jwtDecode } from 'jwt-decode';

export default function loadApplicationInsights(jwtToken) {
  let applicationInsights = null;
  const connectionString =
    window.APPLICATIONINSIGHTS_CONNECTION_STRING ===
    '__APPLICATIONINSIGHTS_CONNECTION_STRING__'
      ? ''
      : window.APPLICATIONINSIGHTS_CONNECTION_STRING;

  if (connectionString) {
    applicationInsights = new ApplicationInsights({
      config: {
        connectionString,
      },
    });

    applicationInsights.loadAppInsights();

    const decodedJwt = jwtDecode(jwtToken);

    applicationInsights.addTelemetryInitializer((envelope) => {
      envelope.data.arterTid = decodedJwt.arter_tid;
      envelope.data.authIssuer = decodedJwt.iss;
      if (
        envelope.baseType === 'PageviewData' ||
        envelope.baseType === 'PageviewPerformanceData'
      ) {
        envelope.baseData.name = window.location.pathname
          .replaceAll(
            /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g,
            '{id}',
          )
          .slice(1);
      }
    });
  }

  return applicationInsights;
}
