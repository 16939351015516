import { useCallback, useEffect } from 'preact/hooks';
import Zoom from './Zoom';
import ZoomIn from './ZoomIn';
import ZoomOut from './ZoomOut';
import Layers from './Layers';
import Exports from './Exports';
import useComponentVisible from '../../../hooks/useComponentVisible';
import './toolbar.css';
import { DEFAULT_LANGUAGE } from '../../../translations';
import FullScreen from './FullScreen';

function Toolbar({
  language = DEFAULT_LANGUAGE,
  drawIoRef,
  uuid,
  xml,
  onExportPng,
  fullScreenEnabled,
}) {
  const {
    ref: zoom,
    visible: zoomDropdownVisible,
    setVisible: setZoomDropdownVisible,
  } = useComponentVisible(false);

  const {
    ref: exports,
    visible: exportsDropdownVisible,
    setVisible: setExportsDropdownVisible,
  } = useComponentVisible(false);

  const {
    ref: layers,
    visible: layersDropdownVisible,
    setVisible: setLayersDropdownVisible,
  } = useComponentVisible(false);

  const toggleZoomDropdown = useCallback(() => {
    setZoomDropdownVisible(!zoomDropdownVisible);
  }, [setZoomDropdownVisible, zoomDropdownVisible]);

  const toggleLayerDropdown = useCallback(() => {
    setLayersDropdownVisible(!layersDropdownVisible);
  }, [setLayersDropdownVisible, layersDropdownVisible]);

  const toggleExportDropdown = useCallback(() => {
    setExportsDropdownVisible(!exportsDropdownVisible);
  }, [setExportsDropdownVisible, exportsDropdownVisible]);

  const handleClickToIframe = useCallback(() => {
    setZoomDropdownVisible(false);
    setExportsDropdownVisible(false);
    setLayersDropdownVisible(false);
  }, [
    setExportsDropdownVisible,
    setLayersDropdownVisible,
    setZoomDropdownVisible,
  ]);

  useEffect(() => {
    if (drawIoRef.current) {
      drawIoRef.current.contentWindow.addEventListener(
        'click',
        handleClickToIframe,
      );
    }
  }, [drawIoRef, handleClickToIframe]);

  return (
    <div className="toolbar" data-testid="toolbar">
      <div className="size-tools-wrapper">
        <Zoom
          ref={zoom}
          toggleDropdown={toggleZoomDropdown}
          showDropdown={zoomDropdownVisible}
          drawIoRef={drawIoRef}
          language={language}
        />
        <ZoomIn drawIoRef={drawIoRef} language={language} />
        <ZoomOut drawIoRef={drawIoRef} language={language} />
        {fullScreenEnabled ? <FullScreen language={language} /> : null}
      </div>
      <Layers
        ref={layers}
        toggleDropdown={toggleLayerDropdown}
        showDropdown={layersDropdownVisible}
        drawIoRef={drawIoRef}
        language={language}
      />
      <Exports
        ref={exports}
        toggleDropdown={toggleExportDropdown}
        showDropdown={exportsDropdownVisible}
        uuid={uuid}
        xml={xml}
        language={language}
        onExportPng={onExportPng}
      />
    </div>
  );
}

export default Toolbar;
