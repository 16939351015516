import { useState, useEffect } from 'preact/hooks';
import axios from 'axios';

function useDrawing(uuid, configuration) {
  const [xml, setXml] = useState('');
  useEffect(() => {
    (async () => {
      if (!xml && uuid && configuration && configuration.authentication) {
        try {
          const response = await axios.get(`/api/v1/drawings/${uuid}`, {
            headers: {
              Authorization: `Bearer ${configuration.authentication.jwt}`,
            },
          });
          setXml(response.data.xml);
        } catch (error) {
          console.error(error.message);
        }
      }
    })();
  }, [uuid, configuration, xml]);

  return xml;
}

export default useDrawing;
