export const ACTION_LOAD = 'load';
export const ACTION_EXPORT = 'export';
export const ACTION_CONFIGURE = 'configure';
export const FORMAT_PNG = 'png';
export const EVENT_INIT = 'init';
export const EVENT_LOAD = 'load';
export const EVENT_SAVE = 'save';
export const EVENT_CONFIGURE = 'configure';
export const EVENT_EXPORT = 'export';
export const EVENT_EXIT = 'exit';

function sendDrawIoMessage(iframe, msg) {
  iframe.contentWindow.postMessage(JSON.stringify(msg), '*');
}

export function sendLoadMessage(iframe, xml) {
  sendDrawIoMessage(iframe, {
    action: ACTION_LOAD,
    autosave: 0,
    xml,
  });
}

export function sendExportPngMessage(iframe) {
  sendDrawIoMessage(iframe, {
    action: ACTION_EXPORT,
    format: FORMAT_PNG,
  });
}

export function sendConfigureMessage(iframe, config) {
  sendDrawIoMessage(iframe, {
    action: ACTION_CONFIGURE,
    config,
  });
}
