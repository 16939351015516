import CheckIcon from 'mdi-preact/CheckIcon';
import DropdownListItemText from './DropdownListItemText';

function DropdownCheckboxItem({ htmlFor, checked, onChange, item, text }) {
  function handleOnChange() {
    onChange(item);
  }

  return (
    <label className="checkbox-container dropdown-list-item" htmlFor={htmlFor}>
      <input
        id={htmlFor}
        checked={checked}
        className="checkbox-input"
        type="checkbox"
        data-testid={htmlFor}
        onChange={handleOnChange}
      />
      <span className="check-icon">
        <CheckIcon />
      </span>
      <DropdownListItemText text={text} />
    </label>
  );
}

export default DropdownCheckboxItem;
