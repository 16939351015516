import { Router } from 'preact-router';
import Editor from './editor/Editor';
import Viewer from './viewer/Viewer';
import './styles.css';

const App = () => (
  <Router>
    <Editor path="/new" />
    <Editor path="/edit/:uuid" />
    <Viewer path="/show/:uuid" />
  </Router>
);

export default App;
