import { useCallback } from 'preact/hooks';
import PlusIcon from 'mdi-preact/PlusIcon';
import ToolbarButton from './ToolbarButton';
import TRANSLATIONS from '../../../translations';
import { sendZoomInMessage } from '../../../util/drawio-plugin-message-api';

function ZoomIn({ drawIoRef, language }) {
  const zoomIn = useCallback(() => {
    sendZoomInMessage(drawIoRef.current);
  }, [drawIoRef]);

  return (
    <ToolbarButton
      onClick={zoomIn}
      tooltipText={TRANSLATIONS.ZOOM_IN[language]}
      testid="zoom-in-btn"
    >
      <PlusIcon />
    </ToolbarButton>
  );
}

export default ZoomIn;
