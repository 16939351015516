import { useEffect, useState, useContext, useMemo } from 'preact/hooks';
import { createContext } from 'preact';
import useDrawing from '../../hooks/useDrawing';
import {
  CONFIGURE,
  REFRESH_JWT,
  sendConfigureMessage,
} from '../../util/parent-system-message-api';
import loadApplicationInsights from '../../util/application-insights';

const ConfigurationContext = createContext({});

function ConfigurationProvider({ uuid, source, lang, ...props }) {
  const [readyToListen, setReadyToListen] = useState(false);
  const [parentSystemConfiguration, setParentSystemConfiguration] =
    useState(null);
  const [applicationInsights, setApplicationInsights] = useState(null);
  const drawingId = uuid || source;
  const xml = useDrawing(drawingId, parentSystemConfiguration);

  const receive = (evt) => {
    if (evt.data.message && evt.data.message === CONFIGURE) {
      setParentSystemConfiguration(evt.data.configuration);
      setApplicationInsights(
        loadApplicationInsights(evt.data.configuration.authentication.jwt),
      );
    }
    if (evt.data.message && evt.data.message === REFRESH_JWT) {
      setParentSystemConfiguration((prevConfiguration) => ({
        ...prevConfiguration,
        authentication: { jwt: evt.data.jwt },
      }));
    }
  };

  useEffect(() => {
    if (readyToListen) {
      sendConfigureMessage();
    }
  }, [readyToListen]);

  useEffect(() => {
    window.addEventListener('message', receive);
    setReadyToListen(true);
    return () => {
      window.removeEventListener('message', receive);
    };
  }, []);

  const configuration = useMemo(() => ({
    parentSystemConfiguration,
    uuid,
    lang,
    xml,
    applicationInsights,
  }), [applicationInsights, lang, parentSystemConfiguration, uuid, xml]);

return parentSystemConfiguration && (xml || !drawingId) ? (
  <ConfigurationContext.Provider
    value={configuration}
  >
    {props.children}
  </ConfigurationContext.Provider>
) : null;
}

function useConfiguration() {
  const context = useContext(ConfigurationContext);
  if (!context) {
    throw new Error(
      `useConfiguration must be used within a ConfigurationProvider`,
    );
  }
  return context;
}

export { ConfigurationProvider, useConfiguration };
