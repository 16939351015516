import DropdownListItemText from './DropdownListItemText';

function DropdownValueItem({ onValueClick, value, text }) {
  function handleOnClick() {
    onValueClick(value);
  }

  return (
    <div className="dropdown-list-item" onClick={handleOnClick}>
      <DropdownListItemText text={text} />
    </div>
  );
}

export default DropdownValueItem;
