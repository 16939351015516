import { useCallback } from 'preact/hooks';
import * as drawIoMessageApi from '../util/drawio-message-api';

function useDrawIoApi(drawIoRef) {
  const configure = useCallback(
    (configuration) =>
      drawIoMessageApi.sendConfigureMessage(drawIoRef.current, configuration),
    [drawIoRef],
  );

  const load = useCallback(
    (xml) => drawIoMessageApi.sendLoadMessage(drawIoRef.current, xml),
    [drawIoRef],
  );

  const exportPng = useCallback(
    () => drawIoMessageApi.sendExportPngMessage(drawIoRef.current),
    [drawIoRef],
  );

  return { configure, load, exportPng };
}

export default useDrawIoApi;
