function ToolbarButton({
  children,
  tooltipText,
  onClick,
  disabled,
  selected,
  testid,
}) {
  return (
    <button
      className={`toolbar-btn ${selected ? 'selected' : ''}`}
      onClick={onClick}
      disabled={disabled}
      data-testid={testid}
    >
      {children}
      {selected ? null : (
        <span className="tooltip" data-testid={`${testid}-tooltip`}>
          {tooltipText}
        </span>
      )}
    </button>
  );
}

export default ToolbarButton;
