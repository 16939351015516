import * as parentSystemMessageApi from '../../util/parent-system-message-api';
import { downloadImage } from './viewer-utils';

export const EXPORT_REQUEST = {
  PARENT_SYSTEM_IMAGE_REQUEST: 'parentSystemImageRequest',
  USER_DOWNLOAD_PNG_REQUEST: 'userDownloadPngRequest',
};

export const EXPORT_QUEUE_ACTION = {
  ADD_REQUEST: 'addRequest',
  PROCESS_REQUEST: 'processRequest',
};

const exportRequestQueueReducer = (state, action) => {
  switch (action.type) {
    case EXPORT_QUEUE_ACTION.ADD_REQUEST: {
      return [...state, action.payload];
    }
    case EXPORT_QUEUE_ACTION.PROCESS_REQUEST: {
      const [nextRequest, ...remainingRequests] = state;
      if (nextRequest === EXPORT_REQUEST.PARENT_SYSTEM_IMAGE_REQUEST) {
        parentSystemMessageApi.sendImageMessage(action.payload.data);
      }
      if (nextRequest === EXPORT_REQUEST.USER_DOWNLOAD_PNG_REQUEST) {
        downloadImage(`${action.payload.uuid}.png`, action.payload.data);
      }

      return remainingRequests;
    }
    default:
      return state;
  }
};

export default exportRequestQueueReducer;
