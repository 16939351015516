function ToolbarButtonWithDropdown({ button, showDropdown, dropdown }) {
  return (
    <div className="toolbar-btn-with-dropdown-container">
      {button}
      {showDropdown ? dropdown : null}
    </div>
  );
}

export default ToolbarButtonWithDropdown;
