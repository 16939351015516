export const CONFIGURE = 'configure';
export const INIT_MENUS = 'initMenus';
export const STATE_SAVING = 'stateSaving';
export const STATE_SAVING_SUCCESS = 'stateSavingSuccess';
export const STATE_SAVING_FAILED = 'stateSavingFailed';
export const GET_LAYERS = 'getLayers';
export const GET_SCALE = 'getScale';
export const RENDER_LINK_ICONS = 'renderLinkIcons';
export const REMOVE_LINK_ICONS = 'removeLinkIcons';
export const ACTIVATE_LINK_ICONS = 'activateLinkIcons';
export const DEACTIVATE_LINK_ICONS = 'deactivateLinkIcons';
export const TOGGLE_LAYER = 'toggleLayer';
export const SCALE = 'scale';
export const ZOOM_IN = 'zoomIn';
export const ZOOM_OUT = 'zoomOut';
export const EXPORT_WITH_OBJECT_DATA = 'exportWithObjectData';
export const HIGHLIGHT = 'highlight';
export const GET_IMAGE = 'getImage';
export const EVENT_EXPORT_WITH_OBJECT_DATA = 'exportWithObjectData';
export const EVENT_HOVER = 'hover';
export const EVENT_HOVER_OUT = 'hoverOut';
export const EVENT_CLICK = 'click';
export const EVENT_CLICK_LINK_ICON = 'clickLinkIcon';
export const ADD_LINK_ICON_INTERACTION_TO_LAYER =
  'addLinkIconInteractionToLayer';

function sendDrawIoMessage(iframe, message) {
  iframe.contentWindow.postMessage(message, '*');
}

export function sendConfigureDeleteConfirmationMessage(
  iframe,
  objectIds,
  message,
) {
  sendDrawIoMessage(iframe, {
    deleteConfirmationAction: CONFIGURE,
    objectIds,
    message,
  });
}

export function sendInitMenusMessage(iframe, config) {
  sendDrawIoMessage(iframe, {
    editorAction: INIT_MENUS,
    config,
  });
}

export function sendGetLayersMessage(iframe) {
  sendDrawIoMessage(iframe, {
    toolbarAction: GET_LAYERS,
  });
}

export function sendGetScaleMessage(iframe) {
  sendDrawIoMessage(iframe, {
    toolbarAction: GET_SCALE,
  });
}

export function sendRenderLinkIconsMessage(iframe, links) {
  sendDrawIoMessage(iframe, {
    iconAction: RENDER_LINK_ICONS,
    links,
  });
}

export function sendRemoveLinkIconsMessage(iframe, objectIds) {
  sendDrawIoMessage(iframe, {
    iconAction: REMOVE_LINK_ICONS,
    objectIds,
  });
}

export function sendActivateLinkIconsMessage(iframe, objectIds) {
  sendDrawIoMessage(iframe, {
    iconAction: ACTIVATE_LINK_ICONS,
    objectIds,
  });
}

export function sendDeactivateLinkIconsMessage(iframe, objectIds) {
  sendDrawIoMessage(iframe, {
    iconAction: DEACTIVATE_LINK_ICONS,
    objectIds,
  });
}

export function sendToggleLayerMessage(iframe, layerId) {
  sendDrawIoMessage(iframe, {
    toolbarAction: TOGGLE_LAYER,
    layerId,
  });
}

export function sendScaleMessage(iframe, scale) {
  sendDrawIoMessage(iframe, {
    toolbarAction: SCALE,
    scale,
  });
}

export function sendZoomInMessage(iframe) {
  sendDrawIoMessage(iframe, {
    toolbarAction: ZOOM_IN,
  });
}

export function sendZoomOutMessage(iframe) {
  sendDrawIoMessage(iframe, {
    toolbarAction: ZOOM_OUT,
  });
}

export function sendExportWithObjectDataMessage(iframe, originalExportMessage) {
  sendDrawIoMessage(iframe, {
    message: EXPORT_WITH_OBJECT_DATA,
    originalExportMessage,
  });
}

export function sendStateSavingSuccessMessage(iframe) {
  sendDrawIoMessage(iframe, {
    editorState: STATE_SAVING_SUCCESS,
  });
}

export function sendStateSavingFailedMessage(iframe) {
  sendDrawIoMessage(iframe, {
    editorState: STATE_SAVING_FAILED,
  });
}

export function sendHighlightMessage(iframe, opacity, objects) {
  sendDrawIoMessage(iframe, {
    styleAction: HIGHLIGHT,
    opacity,
    objects,
  });
}

export function sendAddLinkIconInteractionToLayerMessage(iframe, layerId) {
  sendDrawIoMessage(iframe, {
    iconAction: ADD_LINK_ICON_INTERACTION_TO_LAYER,
    layerId,
  });
}
