import { useEffect } from 'preact/hooks';

// currently drawio doesn't allow untrusted plugins to be configured in embedded mode
// so we have to use this workaround to inject our custom plugins to drawio configuration
// also if no plugins is passed we also want to clear existing plugins
// to avoid unexpected behaviour caused by stale configurations

function setDrawIoPluginsToLocalStorageConfig(plugins) {
  const drawIoConfig = JSON.parse(localStorage.getItem('.drawio-config'));
  localStorage.setItem(
    '.drawio-config',
    JSON.stringify({
      ...drawIoConfig,
      plugins,
    }),
  );
}

function useDrawIoPlugins(plugins) {
  useEffect(() => {
    setDrawIoPluginsToLocalStorageConfig(plugins || []);
  }, [plugins]);
}

export default useDrawIoPlugins;
