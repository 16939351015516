import { useCallback, useEffect, useState } from 'preact/hooks';
import * as drawioMessageApi from '../util/drawio-message-api';

function useDrawIoEvents({
  onConfigure,
  onInit,
  onLoad,
  onSave,
  onExport,
  onExit,
}) {
  const [readyToListen, setReadyToListen] = useState(false);

  const receiveDrawIoMessages = useCallback(
    (evt) => {
      if (evt.data.length > 0) {
        const msg = JSON.parse(evt.data);
        switch (msg.event) {
          case drawioMessageApi.EVENT_CONFIGURE:
            onConfigure?.(msg);
            break;
          case drawioMessageApi.EVENT_INIT:
            onInit?.(msg);
            break;
          case drawioMessageApi.EVENT_LOAD:
            onLoad?.(msg);
            break;
          case drawioMessageApi.EVENT_SAVE:
            onSave?.(msg);
            break;
          case drawioMessageApi.EVENT_EXPORT:
            onExport?.(msg);
            break;
          case drawioMessageApi.EVENT_EXIT:
            onExit?.(msg);
            break;
          default:
            break;
        }
      }
    },
    [onConfigure, onExit, onExport, onInit, onLoad, onSave],
  );

  useEffect(() => {
    window.addEventListener('message', receiveDrawIoMessages);
    setReadyToListen(true);
    return () => {
      window.removeEventListener('message', receiveDrawIoMessages);
    };
  }, [receiveDrawIoMessages]);

  return readyToListen;
}

export default useDrawIoEvents;
