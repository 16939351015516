import { useCallback } from 'preact/hooks';
import * as parentSystemMessageApi from '../util/parent-system-message-api';

function useParentSystemApi() {
  const triggerLoad = useCallback(
    (height) => parentSystemMessageApi.sendLoadMessage(height),
    [],
  );

  const triggerViewerEvent = useCallback(
    (evt) => parentSystemMessageApi.sendViewerEventMessage(evt),
    [],
  );

  const sendImage = useCallback(
    (img) => parentSystemMessageApi.sendImageMessage(img),
    [],
  );

  const triggerExit = useCallback(
    () => parentSystemMessageApi.sendExitMessage(),
    [],
  );

  const triggerJwtUpdate = useCallback(
    () => parentSystemMessageApi.sendInvalidJwtMessage(),
    [],
  );

  const triggerSave = useCallback(
    (uuid, image, objectData) =>
      parentSystemMessageApi.sendSaveMessage(uuid, image, objectData),
    [],
  );

  const triggerUpdate = useCallback(
    (uuid, image, objectData) =>
      parentSystemMessageApi.sendUpdateMessage(uuid, image, objectData),
    [],
  );

  return {
    triggerLoad,
    triggerViewerEvent,
    sendImage,
    triggerExit,
    triggerJwtUpdate,
    triggerSave,
    triggerUpdate,
  };
}

export default useParentSystemApi;
