import { useCallback } from 'preact/hooks';
import MinusIcon from 'mdi-preact/MinusIcon';
import ToolbarButton from './ToolbarButton';
import TRANSLATIONS from '../../../translations';
import { sendZoomOutMessage } from '../../../util/drawio-plugin-message-api';

function ZoomOut({ drawIoRef, language }) {
  const zoomOut = useCallback(() => {
    sendZoomOutMessage(drawIoRef.current);
  }, [drawIoRef]);

  return (
    <ToolbarButton
      onClick={zoomOut}
      tooltipText={TRANSLATIONS.ZOOM_OUT[language]}
      testid="zoom-out-btn"
    >
      <MinusIcon />
    </ToolbarButton>
  );
}

export default ZoomOut;
