import Dropdown from './Dropdown';
import DropdownCheckboxItem from './DropdownCheckboxItem';

function LayerDropdown({ layers, onItemChange }) {
  return (
    <Dropdown testid="layer-dropdown">
      {layers.map((layer) => (
        <DropdownCheckboxItem
          checked={layer.visible}
          item={layer}
          onChange={onItemChange}
          htmlFor={`layer-checkbox-${layer.id}`}
          text={layer.name}
        />
      ))}
    </Dropdown>
  );
}

export default LayerDropdown;
