import DropdownListItemText from './DropdownListItemText';

function DropdownListItem({ onClick, text }) {
  return (
    <div className="dropdown-list-item" onClick={onClick}>
      <DropdownListItemText text={text} />
    </div>
  );
}

export default DropdownListItem;
